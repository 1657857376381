import React, { lazy, useCallback, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Header from "./component/inc/Header";
import Footer from "./component/inc/Footer";
import { PrivateRoute, SuspensedView } from "./component/modules/PrivateRoutes";
import { Toaster } from "react-hot-toast";
import { useAppDispatch, useAppSelector, useLanguage } from "./app/hooks";
import { getUserPaymentMethodsThunk, getUserThunk } from "./redux/thunks/user";
import ChangePassword from "./pages/change-password";
import { ASSET_ID_KEY, TOKEN_KEY } from "./utils/constants";
import { getAssetsList } from "./redux/thunks/assets";
import TransactionSuccess from "./pages/transaction-success";

const Home = lazy(() => import("./pages/Home"));
const Payments = lazy(() => import("./pages/Payments"));
const Profile = lazy(() => import("./pages/Profile"));
const About = lazy(() => import("./pages/about"));
const ContentDeliveryPolicy = lazy(
  () => import("./pages/content-delivery-policy")
);
const RefundPolicy = lazy(() => import("./pages/refund-policy"));
const ContactUs = lazy(() => import("./pages/contact-us"));
const Login = lazy(() => import("./pages/auth/Login"));
const SignUp = lazy(() => import("./pages/auth/SignUp"));
const PrivacyPolicy = lazy(() => import("./pages/legal/PrivacyPolicy"));
const TermOfService = lazy(() => import("./pages/legal/TermOfService"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const OTPVerification = lazy(() => import("./pages/auth/OTPVerification"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const AssetDetail = lazy(() => import("./pages/asset-detail"));

function ScrollToTopMain() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  const dispatch = useAppDispatch();
  const { user, isLoggedIn } = useAppSelector((state) => state.auth);
  const { setLanguage } = useLanguage();
  const [promoCode, setPromoCode] = useState("");

  const [status, setStatus] = useState(false);

  function Videostatus() {
    setStatus(true);
    setTimeout(() => {
      setStatus(false);
    }, 1000);
  }

  // useEffect(() => {
  //   window.location.reload();
  // }, [language]);

  const getUser = useCallback(() => {
    if (
      localStorage.getItem(TOKEN_KEY) &&
      window.location.pathname !== "/otp-verification"
    ) {
      // dispatch(setIsLoggedIn(true));
      dispatch(getUserThunk());
      dispatch(getUserPaymentMethodsThunk());
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (user?.language) {
      setLanguage(user?.language);
    }
  }, [user, setLanguage]);

  useEffect(() => {
    getUser();
    dispatch(getAssetsList());
  }, [isLoggedIn]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const getComponent = (Component: React.FC) => {
    if(isFirstLoad) {
    const location = window.location.href; 
    const urlObj = new URL(location);
    const queryParams = new URLSearchParams(urlObj.search);
    const promoCode = queryParams.get('promo_code');
    if(promoCode){
      setPromoCode(promoCode);
    }
    setIsFirstLoad(false);
  }

    if (localStorage.getItem(TOKEN_KEY)) {
      let assetUrl = localStorage.getItem(ASSET_ID_KEY);
      if (assetUrl) {
        return (
          <Navigate
            state={{
              reload: true,
            }}
            replace
            to={assetUrl}
          />
        );
      }
      let navigateTo = '/';
      if (promoCode) {
        navigateTo = `/?promo_code=${promoCode}`;
      }
      return (
        <Navigate
          replace
          to={navigateTo} // Use the constructed URL
        />
      );
    } else {
      return (
        <>
          <Header stop={Videostatus} />
          <Component />
          <Footer />
        </>
      );
    }
  };
  return (
    <SuspensedView>
      <Router basename={process.env.REACT_APP_BASENAME}>
        <ScrollToTopMain />

        <Toaster position="top-right" />
        <Routes>
        <Route
            path="/"
            element={
              <>
                <Header stop={Videostatus} />
                <Home status={status} />
                <Footer />
              </>
            }
          />
          <Route
            path="/movie/:slug"
            element={
              <>
                {/* <PrivateRoute> */}
                  <Header stop={Videostatus} />
                  <AssetDetail status={status} />
                  <Footer />
                {/* </PrivateRoute> */}
              </>
            }
          />
          <Route
            path="/series/:slug"
            element={
              <>
                {/* <PrivateRoute> */}
                  <Header stop={Videostatus} />
                  <AssetDetail status={status} />
                  <Footer />
                {/* </PrivateRoute> */}
              </>
            }
          />
          <Route
            path="/payments"
            element={
              <PrivateRoute>
                <Header stop={Videostatus} />
                <Payments />
                <Footer />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Header stop={Videostatus} />
                <Profile />
                <Footer />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute>
                <Header stop={Videostatus} />
                <ChangePassword />
                <Footer />
              </PrivateRoute>
            }
          />
          <Route
            path="/transaction-success"
            element={
              <PrivateRoute>
                <Header stop={Videostatus} />
                <TransactionSuccess />
                <Footer />
              </PrivateRoute>
            }
          />
          {/*Auth Pages */}
          <Route
            path="/login"
            element={
              isLoggedIn ? <Navigate to="/" replace /> : <Login />
            }
          />
          <Route path={`/signup`} element={getComponent(SignUp)} />
          <Route
            path={`/forgot-password`}
            element={getComponent(ForgotPassword)}
          />
          <Route
            path={`/otp-verification`}
            element={getComponent(OTPVerification)}
          />
          <Route
            path={`/resetpassword`}
            element={getComponent(ResetPassword)}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
          {/* End Auth Pages */}

          {/* Legal Pages */}
          <Route
            path={`/privacy-policy`}
            element={
              <>
                <Header stop={Videostatus} />
                <PrivacyPolicy />
                <Footer />
              </>
            }
          />
          <Route
            path={`/terms`}
            element={
              <>
                <Header stop={Videostatus} />
                <TermOfService />
                <Footer />
              </>
            }
          />
          <Route
            path={`/about`}
            element={
              <>
                <Header stop={Videostatus} />
                <About />
                <Footer />
              </>
            }
          />
          <Route
            path={`/content-delivery-policy`}
            element={
              <>
                <Header stop={Videostatus} />
                <ContentDeliveryPolicy />
                <Footer />
              </>
            }
          />
          <Route
            path={`/refund-policy`}
            element={
              <>
                <Header stop={Videostatus} />
                <RefundPolicy />
                <Footer />
              </>
            }
          />
          <Route
            path={`/contact-us`}
            element={
              <>
                <Header stop={Videostatus} />
                <ContactUs />
                <Footer />
              </>
            }
          />
          {/* End Of Legal Pages */}
        </Routes>
      </Router>
    </SuspensedView>
  );
}

export default App;
